import React, { Component,useState } from "react";
import {Modal,Button,Col,Row} from "react-bootstrap";
import { NavLink } from "react-router-dom";
import AuthService from "../services/AuthService";
import { ToastContainer, toast } from "react-toastify";
import toaster from "./Toaster";

function Register () {
  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
      return (
     <>
       <Modal
       size="lg"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header className="card_purple">
          <Modal.Title className="txt_sett">Register</Modal.Title>
        </Modal.Header >
        <Modal.Body >
        <Row>
          <Col sm={4}>
          <a className="txt_login"> Username </a>
          </Col>
          <Col sm={8}>
          <input className="form-control" id="username_r"></input>
          </Col>
          <Col sm={4} className="pt-3">
          <a className="txt_login"> Password </a>
          </Col>
          <Col sm={8} className="pt-3">
          <input className="form-control" type="password" id="password_r"></input>
          </Col>
          <Col sm={4} className="pt-3">
          <a className="txt_login"> Full Name </a>
          </Col>
          <Col sm={8} className="pt-3">
          <input className="form-control" id="full_name"></input>
          </Col>
          <Col sm={4} className="pt-3">
          <a className="txt_login"> E-mail </a>
          </Col>
          <Col sm={8} className="pt-3">
          <input className="form-control" id="email" placeholder="abcs@gmail.com"></input>
          </Col>
          <Col sm={4} className="pt-3">
          <a className="txt_login"> Mobile Number </a>
          </Col>
          <Col sm={8} className="pt-3">
          <input className="form-control" id="mb_number" ></input>
          </Col>
        </Row>
        </Modal.Body>
        <Modal.Footer className="card_purple ">
          <NavLink  to="" className="btn btn-outline-light btn_outlinex text-white float-left">Login</NavLink>
          <button variant="primary" className="btn btn-outline-light btn_outlinex" onClick={() => AuthService.createmember()}>Register</button>
             </Modal.Footer>
      </Modal>
      <ToastContainer autoClose={2000} draggableDirection="y" />
</>
      );

  }

  export default Register;
  