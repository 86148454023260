import React, { Component } from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Col,Container,Row, } from "react-bootstrap";
import $, { parseJSON } from 'jquery';
import axios from "axios";
import AuthService from "./../../services/AuthService";
import Toaster from "../../component/Toaster";
function submit() {
  // POST request using axios with set headers
  const random = Math.floor(Math.random() * 99999999);
  const get_token = parseJSON(window.localStorage.getItem("token"));
  const api = 'https://node-api.reviz-project.com/api/Birjo_Service/test';
  const data = {
    "ID"             : "case_"+random+"",
    "ID_Case"        : 2,
    "ID_User"        : get_token[0].ID_User,
    "Description"    : $("#desc").val()
               }
     axios.post(api, data)
     .then(response => {
      //  console.log(response)
      Toaster.Toast_success()
     })
     .catch(error => {
         // this.setState({ errorMessage: error.message });
         console.error('There was an error!', error);
     });
}
var usernames = '';
var fullname = '';
var email = '';
var mobile_number = '';
let get_token = localStorage.getItem("token-x");

if (get_token == 'rvzys_20') {
  let session = JSON.parse(localStorage.getItem('token'));      
  usernames = session[0].Username;
  fullname = session[0].Full_Name;
  email = session[0].Email;
  mobile_number = session[0].Mobile_Number;  
  }

class Body extends Component {
    constructor(props) {
        super(props);
        
    }

    render() {
        return (
          <>
        <Row className="p-2 txt-left">
              <Col sm={4} className="txt_sett">Name</Col>
              <Col sm={8}><input className="form-control" id="name" value={fullname} disabled></input></Col>

              <Col sm={4} className="txt_sett">E-mail</Col>
              <Col sm={8}><input className="form-control mt-2" id="email" value={email} disabled></input></Col>

              <Col sm={4} className="txt_sett">Phone Number</Col>
              <Col sm={8}><input className="form-control mt-2" id="phone_num" value={mobile_number} disabled></input></Col>

              <Col sm={4} className="txt_sett">Kasus/Case</Col>
              <Col sm={8}><input className="form-control mt-2" value="Pengesahan STNK" disabled></input></Col>

              <Col sm={4} className="txt_sett">Keluhan/Deskripsi</Col>
              <Col sm={8}><textarea className="form-control mt-2" id="desc"></textarea></Col>

              <Col md className="mt-3 txt-right">
              <button className="btn btn-outline-light btn_outlinex" onClick={submit}>Submit</button>
              </Col>
              
        </Row>
          </>
        );
    }
  }
  export default Body;