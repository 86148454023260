import GetService from "../../../services/GetService";
import AuthService from "../../../services/AuthService";


const IsLoggedIn = (state = [], action) =>{
    switch (action.type) {
      case "SIGN_IN":
      let id = AuthService.Auth_Account_Detail()[0].ID_User;
      // console.log("auth",AuthService.Auth_Account_Detail())
        GetService.getusernoPassbyID(id).then((res) => {
          // state = []
          var isi = res.data[0];
          state.push(isi);
          console.log("ias",state)
          // state = this.state.filter((obj, pos, arr) => {
          //   return arr
          //     .map(mapObj => mapObj.ID_User)
          //     .indexOf(obj.name) == pos;
          // });
          return state 
        })
      default : 
        return state 
    }
}
export default IsLoggedIn;