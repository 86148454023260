import React, { Component, useEffect, useState } from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Col,Container,Row,Card,Tab,Tabs,Sonnet,Button} from "react-bootstrap";
import $, { parseJSON } from 'jquery';
import axios from "axios";
import GetService from "./../../services/GetService";
import DataTable,{createTheme,defaultThemes} from 'react-data-table-component';
import toaster from "../../component/Toaster";
import { ToastContainer, toast } from "react-toastify";
function Conditional_button(status,id_case) {
  if (status == "Belum Selesai") {
    return <Button variant="outline-light" onClick={() => getcase(id_case)}>Finish</Button>;  
  }else{
    return <Button variant="outline-light" onClick={() => getcase(id_case)}>X</Button>;  
  }
}
function getcase(id_case) {
  // alert(id_case)
  let obj = {
    Status  : "Selesai",
    ID      : id_case
  }
  axios.post('https://node-api.reviz-project.com/api/Birjo_Service/update_case' , obj)
        .then((res) => {
          toaster.Toast_success_update();
      })
      .catch((err) => {
        toaster.Toast_error_custom(err);
        // console.log("AXIOS ERROR: ", err);
      })
}
const Table_ongoing = () => {
    const [data,setdata] = useState();
    useEffect(()=>{
        var datax = [];
        GetService.getCase().then((res) => {
        // console.log("RESPONSE RECEIVED: ", res.data);
        var isi = res.data;
        let no = 1;
        for (let index = 0; index < res.data.length; index++) {
          let  obj = {
                no          : no,
                name        : isi[index].Full_Name,
                email       : isi[index].Email,
                mobile_no   : isi[index].Mobile_Number,
                case_name   : isi[index].Case_Name,
                desc        : isi[index].Description,
                datetime    : isi[index].Datetime,
                status      : isi[index].Status,
                id          : isi[index].ID
            }
            datax.push(obj)
        no++
        }
        setdata(datax)
      })
    }, []);
          const columns = [
            {
                name: 'No',
                selector: row => row.no,
                sortable: true,
            },
            {
                name: 'Name',
                selector: row => row.name,
                sortable: true,
            },
            {
                name: 'Email',
                selector: row => row.email,
                sortable: true,
            },
            {
                name: 'Mobile Number',
                selector: row => row.mobile_no,
                sortable: true,
            },
            {
                name: 'Case Name',
                selector: row => row.case_name,
                sortable: true,
            },
            {
                name: 'Description',
                selector: row => row.desc,
                sortable: true,
            },
            {
                name: 'Datetime',
                selector: row => row.datetime,
                sortable: true,
            },
            {
                name: 'Status',
                selector: row => row.status,
                sortable: true,
            },
            	{
                name:"Properties",
                // when: row => row.status == "Belum Selesai",
                cell: row => Conditional_button(row.status,row.id)
	},
        ]; 
        const conditionalRowStyles = [
	{
		when: row => row.status == "Selesai",
		style: {
			backgroundColor: 'rgba(63, 195, 128, 0.9)',
			color: 'white',
			'&:hover': {
				cursor: 'pointer',
			},
		},
	},
    	{
		when: row => row.status == "Belum Selesai",
		style: {
			backgroundColor: 'rgba(242, 38, 19, 0.9)',
			color: 'white',
			'&:hover': {
				cursor: 'pointer',
			},
		},
	}
];
        createTheme('solarized', {
  text: {
    primary: '#212529',
    secondary: '#2aa198',
  },
  background: {
    default: '#FFFFFF',
  },
  context: {
    background: '#cb4b16',
    text: '#FFFFFF',
  },
  divider: {
    default: '#073642',
  },
  action: {
    button: 'rgba(0,0,0,.54)',
    hover: 'rgba(0,0,0,.08)',
    disabled: 'rgba(0,0,0,.12)',
  },
}, 'dark');
const customStyles = {
	header: {
		style: {
			minHeight: '56px',
		},
	},
	headRow: {
		style: {
			borderTopStyle: 'solid',
			borderTopWidth: '1px',
			borderTopColor: defaultThemes.default.divider.default,
		},
	},
	headCells: {
		style: {
			'&:not(:last-of-type)': {
				borderRightStyle: 'solid',
				borderRightWidth: '1px',
				borderRightColor: defaultThemes.default.divider.default,
			},
		},
	},
	cells: {
		style: {
			'&:not(:last-of-type)': {
				borderRightStyle: 'solid',
				borderRightWidth: '1px',
				borderRightColor: defaultThemes.default.divider.default,
			},
		},
	},
};
        return (
            <DataTable
			title="Case list"
			columns={columns}
			data={data}
			pagination
            highlightOnHover
		    pointerOnHover
        	customStyles={customStyles}
             conditionalRowStyles={conditionalRowStyles}
            //  theme="solarized"
		/>
        );     
}

  export const Body = props => {
  
        return (
       <>
     	<Table_ongoing />
  </>
        );
  
    }
  export default Body;