import React, { Component } from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Col,Container,Row,Card,Tab,Tabs,Sonnet} from "react-bootstrap";
import Body from './Body';
import Rules from './Rules';
import $ from 'jquery';
import Loading from './../../component/Loading';
import {
  Route,
} from "react-router-dom";



class Pengesahan_Stnk extends Component {
    render() {
      setTimeout(() => {
        $(".Loader_Reviz").hide();      
        
      }, 350);
      return (        
     <>
   <Route exact path="/Pelayanan/Pengesahan_Stnk" component={Loading} /> 
<Container className="text-center pt-4" fluid>
<Row>
<Col sm></Col>
    <Col sm={8}> <div className="Perpanjangan_STNK">
    <Card className="card_purple">
  <Card.Body className="txt_title">
  <Tabs  defaultActiveKey="Pengesahan_Stnk" transition={false} id="noanim-tab-example" className="mb-3">
  <Tab eventKey="Pengesahan_Stnk" title="Pengesahan STNK">
    <Body/>
  </Tab>
  <Tab eventKey="syarat" title="Syarat">
    <Rules />
  </Tab>
</Tabs>
  </Card.Body>
</Card>
        </div></Col>
    <Col sm></Col>
    </Row>
</Container>


</>
      );
    }
  }
  export default Pengesahan_Stnk;