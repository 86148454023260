import {
    toast
} from 'react-toastify';
import $, { parseJSON } from 'jquery';
import { Route,Redirect } from "react-router";
class Toaster {
    Toast_login_success() {
        toast.success("Login Success !", {
            position: toast.POSITION.TOP_RIGHT
        });
        setTimeout(() => {
                  window.location.href = '/Home'; 
        }, 1000);
    }
     Toast_login_error() {
        toast.error("Login Failed please check again your Username/Password.", {
            position: toast.POSITION.TOP_RIGHT
        });
     }
    Toast_Create_success() {
        $("#username_r").val("")
        $("#password_r").val("")
        $("#email").val("")
        $("#mb_number").val("")
        $("#full_name").val("")
        toast.success("Create Account Success!", {
            position: toast.POSITION.TOP_RIGHT
        });
    }
    Toast_Error_Create_success(error) {
        toast.warn(error, {
            position: toast.POSITION.TOP_RIGHT
        });
    }
    Toast_Warn_Create_success() {
        toast.warn("Form input can't be empty !", {
            position: toast.POSITION.TOP_RIGHT
        });
    }
    Toast_success(){
         toast.success("Your form has been submited !", {
            position: toast.POSITION.TOP_RIGHT
        });
    }
    Toast_success_update(){
        toast.success("Case has been updated!", {
           position: toast.POSITION.TOP_RIGHT
       });
       setTimeout(() => {
        window.location.href = '/Case'; 
       }, 500);
   }
    Toast_error_update() {
        toast.error("Error update case !", {
            position: toast.POSITION.TOP_RIGHT
        });
    }
    Toast_error_custom(err) {
        toast.error(err, {
            position: toast.POSITION.TOP_RIGHT
        });
    }
    Toast_warn() {
        toast.warn("Warning Notification !", {
            position: toast.POSITION.BOTTOM_LEFT
        });
    }
    Toast_info() {
        toast.info("Info Notification !", {
            position: toast.POSITION.BOTTOM_CENTER
        });
    }
    Toast_custom() {
        toast("Custom Style Notification with css class!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: 'foo-bar'
        });
    }

    Toast_update_user(){
        toast.success("Your account has been updated !", {
           position: toast.POSITION.TOP_RIGHT
       });
   }
}

export default new Toaster();