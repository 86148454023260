import React, { Component } from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Col,Container,Row } from "react-bootstrap";
import $ from 'jquery';
import Loading from './../component/Loading';



class Home extends Component {
    render() {
      setTimeout(() => {
        $(".Loader_Reviz").hide();        
      }, 350);
      return (
        
     <>
<Loading />     
<div className="contents">

</div>
</>
      );
    }
  }
  export default Home;