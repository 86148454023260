import React, { Component } from "react";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import Content from "../Content"




class Home extends Component {
    constructor(props) {
        super(props);
        
    }

    render() {
        return (
          <>
     <Header/>
     <Content />
     <Footer />
          </>
        );
    }
  }
  export default Home;