import React, { Component } from "react";
import { Route,Redirect } from "react-router";
import AuthService from "./AuthService";

export const UnprotectedRoute = ({ component: Component, ...rest}) =>{
    return(
<Route
{...rest}
render={props =>{
       if (AuthService.isAuthenticated() == false) {
        return <Component {...props} />
    }else{
        return <Redirect to="/Home" />
    }
    }
}
/> 
    )
}
