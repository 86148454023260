import React, { Component } from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Col,Container,Row, } from "react-bootstrap";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import Perpanjangan_STNK from "./Perpanjangan_STNK";
import $ from 'jquery';



class Main_Perpanjangan_STNK extends Component {
    constructor(props) {
        super(props);
        
    }

    render() {
        return (
          <>
     <Header/>
     <Perpanjangan_STNK />
     <Footer />
          </>
        );
    }
  }
  export default Main_Perpanjangan_STNK;