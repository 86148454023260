import React, { Component } from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Col,Container,Row, } from "react-bootstrap";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import Cabut_berkas from "./Cabut_berkas";
import $ from 'jquery';



class Main_Cabut_berkas extends Component {
    constructor(props) {
        super(props);
        
    }

    render() {
        return (
          <>
     <Header/>
     <Cabut_berkas />
     <Footer />
          </>
        );
    }
  }
  export default Main_Cabut_berkas;