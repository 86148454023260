import React, { Component,useState } from "react";
import {Modal,Button,Col,Row} from "react-bootstrap";
import { NavLink } from "react-router-dom";
import AuthService from "../../../services/AuthService";


function Modal_Forgot_Password (props) {
  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
      return (
     <>
<Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="card_purple">
        <Modal.Title className="txt_sett" id="contained-modal-title-vcenter">
          Forgot Password
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Row>
      <Col sm={4}>
          <a className="txt_login" > Email </a>
          </Col>
          <Col sm={8}>
          <input id="email_inp" className="form-control"  />  
          </Col>
          </Row>
      </Modal.Body>
      <Modal.Footer className="card_purple cus_footer">
        <Button onClick={props.onHide} className="btn btn-outline-light btn_outlinex">Close</Button>
        <Button  className="btn btn-outline-light btn_outlinex">Submit</Button>
      </Modal.Footer>
    </Modal>
</>
      );

  }
  export default Modal_Forgot_Password;
  