import React, { Component,useState } from "react";
import {Modal,Button,Col,Row} from "react-bootstrap";
import { NavLink } from "react-router-dom";
import './../css/custom_modal.css';

function Loading () {
  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
      return (
     <>
  <div className="Loader_Reviz">
        <div className="wrap">
            <div className="one common"></div>
            <div className="two common"></div>
            <div className="three common"></div>
            <div className="four common"></div>
            <div className="box">
                <div className="circle"></div>
            </div>
        </div>
    </div>
</>
      );

  }
  export default Loading;
  