import React, { Component } from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Perpanjangan_STNK from '../content/Perpanjangan_Stnk/Perpanjangan_STNK';
import Pengesahan_Stnk from '../content/Pengesahan_Stnk/Pengesahan_Stnk';
import Footer from './Footer';
import { Col,Container,Row } from "react-bootstrap";
import $ from 'jquery';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";


class Services extends Component {
    render() {
      return (  
     <>

<Container className="" fluid>
<Router>
     <Switch>
     <Route path="/Perpanjangan_STNK">
          <Perpanjangan_STNK />          
          
          </Route>
          <Route path="/Pengesahan_STNK">
          <Pengesahan_Stnk />         
          
          </Route>
          <Route path="/Balik_nama">
          
          
          
          </Route>
          <Route path="/Mutasi_kendaraan">
          
          
          
          </Route>
          <Route path="/Cabut_berkas">
          
          
          
          </Route>
          <Route path="/STNK_hilang">
          
          
          
          </Route>
          <Route path="/Kir">
          
          
          
          </Route>
          </Switch>
          </Router>
</Container>


</>
      );
    }
  }
  export default Services;