import $, { parseJSON } from 'jquery';
import axios from "axios";
import { Redirect } from 'react-router';
import toaster from "../component/Toaster";
import { ToastContainer, toast } from "react-toastify";
import GetService from './GetService';

class AuthService {
 login(props){
    const obj = {
        "Username" : $("#username_l").val(),
        "Password"  : $("#password_l").val()
      }
      const isNotEmpty = Object.values(obj).every(x => (x != ''));

      if (isNotEmpty) {
   axios.post('https://node-api.reviz-project.com/api/Birjo_Service/login' , obj)
        .then((res) => {
              // console.log("RESPONSE RECEIVED: ", res.data);
          if (res.data.length != 0) {
            localStorage.setItem('token', JSON.stringify(res.data) );
        localStorage.setItem('token-x', 'rvzys_20' );
        toaster.Toast_login_success();
  
          }else{
            toaster.Toast_login_error();
          }
    
      })
      .catch((err) => {
        alert(err)
        localStorage.setItem('token-x', 'rvzno_20' );
        window.location.href = '/Login'; 
        // props.history.push("/Login");   
        console.log("AXIOS ERROR: ", err);
      })
        
      }else{
        toaster.Toast_Warn_Create_success()
      }
}
re_set_storage(data){
  const api = 'https://node-api.reviz-project.com/api/Birjo_Service/update_profile';
     axios.post(api, data)
     .then(response => {
       GetService.getusernoPassbyID(data.ID_User).then((res) => {
        toaster.Toast_update_user();
        localStorage.setItem('token', JSON.stringify(res.data) );
        setTimeout(() => {
          window.location.href = window.location.href;
        }, 500);
        })
        .catch((err) => {
          toaster.Toast_Error_Create_success(err)
        })    
     })
     .catch(error => {
         console.error('There was an error!', error);
     });
}
logout(){
  localStorage.clear()
  localStorage.setItem('token-x', 'rvzno_20' );
  window.location.href = '/Login'; 
}
createmember() {
  let randoms = Math.floor(Math.random() * 99999999);
  let random_ID = "rvz_"+randoms;
  const obj = {
    "ID"                   : random_ID,
    "Username"             : $("#username_r").val(),
    "Password"             : $("#password_r").val(),
    "Email"                : $("#email").val(),
    "Mobile_Number"        : $("#mb_number").val(),
    "Full_Name"            : $("#full_name").val(),
    "Role"                 : 2
  }
  const isNotEmpty = Object.values(obj).every(x => (x != ''));

  if (isNotEmpty) {
    let res =  axios.post('https://node-api.reviz-project.com/api/Birjo_Service/createmember' , obj);
    res.then((res) => {
    // alert("account successfully created")
    toaster.Toast_Create_success()
    })
    .catch((err) => {
      toaster.Toast_Error_Create_success(err)
    // console.log("AXIOS ERROR: ", err);
    })    
  } else{
   toaster.Toast_Warn_Create_success()
  }

}
Auth_Account_Detail(){
  let get_token = localStorage.getItem("token");
  let json = parseJSON(get_token)
  return json;
}

isAuthenticated(){
  let get_token = localStorage.getItem("token-x");
  let authenticated = false;

if (get_token == 'rvzys_20') {
  authenticated = true;
  return authenticated; 
}else if(get_token == null){
  authenticated = false;
  return authenticated;
}else{
  authenticated = false;
  return authenticated;
}
 
}

}

export default new AuthService();