import React, { Component } from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Col,Container,Row,Card,Tab,Tabs,Sonnet} from "react-bootstrap";
import Body from './Body';
import Loadingx from './../../component/Loading';
import $ from 'jquery';
import {
  Route,
} from "react-router-dom";


class Balik_nama extends Component {

    render() {
      setTimeout(() => {
        $(".Loader_Reviz").hide();        
      }, 350);
      return (        
     <>
           <Route exact path="/My_Case" component={Loadingx} /> 
<Container className="text-center pt-4" fluid>
<Row>
    <Col sm={12}> <div className="Perpanjangan_STNK">
    <Card className="card_purple">
  <Card.Body className="txt_title">
<Body />
  </Card.Body>
</Card>
        </div></Col>
    </Row>
</Container>


</>
      );
    }
  }
  export default Balik_nama;