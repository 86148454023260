import React, { Component,useState } from "react";
import {Modal,Button,Col,Row} from "react-bootstrap";
import { NavLink } from "react-router-dom";
import $, { parseJSON } from 'jquery';
import AuthService from "../../../services/AuthService";
import GetService from "../../../services/GetService";
import Toaster from "../../../component/Toaster";
import { ToastContainer, toast } from "react-toastify";

function updateProfile() {
  let match_password_inp = $("#match_password_inp").val();
  var checkBox = document.getElementById("cekbox");
  let get_token = localStorage.getItem("token-x");
  if (get_token == 'rvzys_20') {
    let idxx = AuthService.Auth_Account_Detail()[0].ID_User;
  // console.log(checkBox.checked)
  if (checkBox.checked == true && match_password_inp != "") {
    let data = {
      "Full_Name"         : $("#fullname_inp").val(),
      "Email"             : $("#email_inp").val(),
      "Mobile_Number"     : $("#mobile_number_inp").val(),
      "Password"          : match_password_inp,
      "ID_User"           : idxx,
                 }
      AuthService.re_set_storage(data)
  }else if (checkBox.checked == false){
    GetService.getuserbyID(idxx).then((res) => {
      let oldpass = res.data[0].Password;
    let data = {
        "Full_Name"         : $("#fullname_inp").val(),
        "Email"             : $("#email_inp").val(),
        "Mobile_Number"     : $("#mobile_number_inp").val(),
        "Password"          : oldpass,
        "ID_User"           : idxx,
                   }
      AuthService.re_set_storage(data)
                  })
  }else{
    Toaster.Toast_warn()
  }
                  
    }
}
function old_pass(val) {
  let get_token = localStorage.getItem("token-x");
  if (get_token == 'rvzys_20') {
    let idxx = AuthService.Auth_Account_Detail()[0].ID_User;
    GetService.getuserbyID(idxx).then((res) => {
let oldpass = res.data[0].Password;
if (val == oldpass) {
    $("#match_password_inp").removeAttr("disabled","disabled")
}else{
    $("#match_password_inp").attr("disabled","disabled")
}
    })
  }
}

function changebox(val) {
if (val == true) {
  $("#old_password_inp").removeAttr("disabled","disabled")
} else {
  $("#old_password_inp").attr("disabled","disabled")
}
}

function Modal_Get (props) {
  const [show, setShow] = useState(true);
  const [oldpass, setoldpass] = useState('')
  let fullname = '';
  let username = '';
  let mobile_number = '';
  let email = '';
  let get_token = localStorage.getItem("token-x");
  old_pass(oldpass)

  if (get_token == 'rvzys_20') {
    let session = JSON.parse(localStorage.getItem('token'));      
    fullname = session[0].Full_Name;
    username = session[0].Username;
    mobile_number = session[0].Mobile_Number;
    email = session[0].Email;
    }
      return (
     <>
<Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="card_purple">
        <Modal.Title className="txt_sett" id="contained-modal-title-vcenter">
          Edit Profile
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Row>
          <Col sm={12}>
          <a className="txt_login"> Full Name </a>
          </Col>
          <Col sm={12}>
          <input id="fullname_inp" className="form-control" defaultValue={fullname} />  
            </Col>
       
          <Col sm={12}>
          <input className="" id="cekbox" type="checkbox" onChange={event => changebox(event.target.checked)} /> <a className="txt_login"> Password </a>
          </Col>
          
          <Col sm={6}>
          <input type="password" id="old_password_inp" disabled onChange={event => setoldpass(event.target.value)}  className="form-control" placeholder="old password" />  
          </Col>
          <Col sm={6}>
          <input type="password" id="match_password_inp" className="form-control" placeholder="new password" disabled/>  
          </Col>

          <Col sm={12}>
          <a className="txt_login"> Mobile Number </a>
          </Col>
          <Col sm={12}>
          <input id="mobile_number_inp" className="form-control" defaultValue={mobile_number} />  
          </Col>

          <Col sm={12}>
          <a className="txt_login" > Email </a>
          </Col>
          <Col sm={12}>
          <input id="email_inp" className="form-control" defaultValue={email} />  
          </Col>
          </Row>
      </Modal.Body>
      <Modal.Footer className="card_purple cus_footer">
        <Button onClick={props.onHide} className="btn btn-outline-light btn_outlinex">Back</Button>
        <Button onClick={() => updateProfile()} className="btn btn-outline-light btn_outlinex">Save</Button>
      </Modal.Footer>
    </Modal>
</>
      );

  }
  export default Modal_Get;
  