import React, { Component,useState } from "react";
import {Modal,Button,Col,Row} from "react-bootstrap";
import { NavLink } from "react-router-dom";
import AuthService from "./../services/AuthService";
import {Redirect} from "react-router-dom";
import Modal_Forgot_Password from "./../content/Modal/Forgot_Password/Modal_Forgot_Password"


export const Login = props => {
  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [modalShow, setModalShow] = React.useState(false);
  console.log(props)
  if (AuthService.isAuthenticated() == false) {
    sessionStorage.clear();
    localStorage.clear();
  }
      return (
     <>
       <Modal
       size="md"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header className="card_purple">
          <Modal.Title className="txt_sett">Login</Modal.Title>
        </Modal.Header >
        <Modal.Body >
        <Row>
          <Col sm={4}>
          <a className="txt_login"> Username </a>
          </Col>
          <Col sm={8}>
          <input className="form-control" id="username_l"></input>
          </Col>
          <Col sm={4} className="pt-3">
          <a className="txt_login"> Password </a>
          </Col>
          <Col sm={8} className="pt-3">
          <input className="form-control" type="password"  id="password_l"></input>
          </Col>
          <Col sm={5}>
          <a className="txt_login forgot_txt">Forgot password your password ?</a>
          </Col>
          <Col sm={7}>
          <a className="txt_login forgot_txt_x" onClick={() => setModalShow(true)}> Click here.</a>
          </Col>
        </Row>
        </Modal.Body>
        <Modal.Footer className="card_purple cus_footer">
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
          <NavLink  to="/Register" className="btn btn-outline-light btn_outlinex">Register</NavLink>
          <button variant="primary" className="btn btn-outline-light btn_outlinex" onClick={() => AuthService.login(props)}>Login</button>
          {/* <button variant="primary" className="btn btn-outline-light btn_outlinex" onClick={() => test(props)}>test</button> */}
        </Modal.Footer>
      </Modal>
      <Modal_Forgot_Password
   show={modalShow}
   onHide={() => setModalShow(false)} />

</>
      );

  }
  // function LoginThrough(props) {
  //   AuthService.login();
  //   // console.log("auth",AuthService.isAuthenticated())
  //   if (AuthService.isAuthenticated() == true) {
  //     props.history.push("/Home");        
  //   } else {
  //     props.history.push("/Login");
  //   }
  // }
  // function handleChange (value) {
  //   console.log(value.target.value)
  // }
  export default Login;
  