import React, { Component } from "react";
import { Route,Redirect } from "react-router";
import AuthService from "./AuthService";

export const ProtectedRoute_Case = ({ component: Component, ...rest}) =>{
    return(
<Route
{...rest}
render={props =>{
    console.log(AuthService.isAuthenticated())
    if(AuthService.isAuthenticated() == true && AuthService.Auth_Account_Detail()[0].Role == 1){
        return <Component {...props} />
    }else if(AuthService.isAuthenticated() == true && AuthService.Auth_Account_Detail()[0].Role == 2){
        return <Redirect to={
            {
                pathname: "/Home",
                state:  {
                    from: props.location
                }
            } 
        }/>
    }
    else{
        return <Redirect to={
            {
                pathname: "/login",
                state:  {
                    from: props.location
                }
            } 
        }/>
    }
    }
}
/> 
    )
}
