import React from 'react';
import {
  TransitionGroup,
  CSSTransition
} from "react-transition-group";
import ReactDOM from 'react-dom';
import "react-toastify/dist/ReactToastify.css";
import './css/index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './component/Header';
import Footer from './component/Footer';
import Services from './component/Services';
import Login from './component/Login';
import Register from './component/Register';
import Content from './content/Content';
import reportWebVitals from './reportWebVitals';
// import LoginComponent from './component/LoginComponent';
// content
import Home from './content/Home/Home';
import Main_Perpanjangan_STNK from './content/Perpanjangan_Stnk/Main_Perpanjangan_STNK';
import Main_PengesahanSTNK from './content/Pengesahan_Stnk/Main_Pengesahan_Stnk';
import Main_BalikNama from './content/Balik_nama/Main_Balik_nama';
import Main_CabutBerkas from './content/Cabut_berkas/Main_Cabut_berkas';
import Main_Kir from './content/Kir/Main_Kir';
import Main_MutasiKendaraan from './content/Mutasi_kendaraan/Main_Mutasi_kendaraan';
import Main_STNKHilang from './content/STNK_hilang/Main_STNK_hilang';
import Case from './content/Case/Main'
import Cust_Case from './content/Cust_Case/Main'
import { ProtectedRoute } from './services/ProctectedRoute.Service';
import { ProtectedRoute_Case } from './services/ProctectedRoute_Case.Service';
import { ProtectedRoute_CustCase } from './services/ProctectedRoute_CustCase.Service';
import {UnprotectedRoute} from './services/UnprotectedRoute.Service';
import { ToastContainer, toast } from "react-toastify";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
  BrowserRouter,
} from "react-router-dom";
import { createStore } from 'redux';
import allReducers from './component/Redux/Reducers';
import { Provider,useSelector } from 'react-redux';

// --- REDUX ---
// Store -> Global variable

// Action
// const getDetail = () => {
//   return {
//     type  : "REZA"
//   }
// }
// // Reducer
// const counter = (state = "", action) =>{
//   switch (action.type) {
//     case "REZA":
//       alert("get reza",action.type)
//       return state = action.type;  
//       case "REZA x":
//       alert("nope")
//       return state = "None";
//   }

// }
// let store = createStore(counter)
// // console
// store.subscribe(()=> console.log(store.getState()))
// // Dispatch
// store.dispatch(getDetail())
let store = createStore(allReducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
function App_main(){
  const islog = useSelector(state => state.IsLoggedIn)
  console.log("islog",islog)
  return(

    <Router>
    <Switch>
         <Route exact path="/">
         <Redirect to="/Login" />
         </Route>
         <UnprotectedRoute exact path="/Login" component={Login} />      
         <UnprotectedRoute exact path="/Register" component={Register} />
         <ProtectedRoute exact path="/Home" component={Home}/>    
         <ProtectedRoute exact path="/Pelayanan/Perpanjangan_STNK" component={Main_Perpanjangan_STNK}/>         
         <ProtectedRoute exact path="/Pelayanan/Pengesahan_STNK" component={Main_PengesahanSTNK}/>
         <ProtectedRoute exact path="/Pelayanan/Balik_nama" component={Main_BalikNama}/>
         <ProtectedRoute exact path="/Pelayanan/Mutasi_kendaraan" component={Main_MutasiKendaraan}/>         
         <ProtectedRoute exact path="/Pelayanan/Cabut_berkas" component={Main_CabutBerkas}/>
         <ProtectedRoute exact path="/Pelayanan/STNK_hilang" component={Main_STNKHilang}/>
         <ProtectedRoute exact path="/Pelayanan/Kir" component={Main_Kir}/>
         <ProtectedRoute_Case exact path="/Case" component={Case}/>
         <ProtectedRoute_CustCase exact path="/My_Case" component={Cust_Case}/>
         <Route path="*" component={()=> "404"} />
      
       </Switch>
  </Router>
  );
}
const rootElement =  document.getElementById('root'); 
ReactDOM.render(
  <Provider store={store}>
    <App_main />
    <ToastContainer autoClose={2000} draggableDirection="y" />

  </Provider>
  // <BrowserRouter>
  // </BrowserRouter>
,
rootElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
