import React, { Component } from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Col,Container,Row, } from "react-bootstrap";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import Pengesahan_Stnk from "./Pengesahan_Stnk";
import $ from 'jquery';



class Main_Pengesahan_Stnk extends Component {
    constructor(props) {
        super(props);
        
    }

    render() {
        return (
          <>
     <Header/>
     <Pengesahan_Stnk />
     <Footer />
          </>
        );
    }
  }
  export default Main_Pengesahan_Stnk;