import $, { parseJSON } from 'jquery';
import axios from "axios";
import { Redirect } from 'react-router';
import toaster from '../component/Toaster';

class GetService {
getCase(){
    let res =  axios.get('https://node-api.reviz-project.com/api/Birjo_Service/generateCase');
    return res;
}
getCase_User(ID_User){
    let res =  axios.get('https://node-api.reviz-project.com/api/Birjo_Service/generateCase_User',{ params: { ID_User: ID_User } });
    return res;
}
getCase_Status(){
    let res =  axios.get('https://node-api.reviz-project.com/api/Birjo_Service/generateCase_Status');
    return res;
}
getuserbyID(ID_User){
    let res =  axios.get('https://node-api.reviz-project.com/api/Birjo_Service/getuserbyID',{ params: { ID_User: ID_User } });
    return res;
}
getusernoPassbyID(ID_User){
    let res =  axios.get('https://node-api.reviz-project.com/api/Birjo_Service/getusernoPassbyID',{ params: { ID_User: ID_User } });
    return res;
}

}

export default new GetService();