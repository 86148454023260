import React, { Component } from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Col,Container,Row,Image } from "react-bootstrap";
import IG from '../img/ig.png';
import LOC from '../img/loc.png';
import WA from '../img/WA.png';
import { ToastContainer, toast } from "react-toastify";
class Footer extends Component {
    render() {
      return (
     <>
<div className="footer_reviz">
<Container className="text-center foot_ico" fluid>
  <Row >
    <Col><Image className="img_footer" src={IG} /> Instagram</Col>
    <Col><Image className="img_footer" src={LOC} />Address</Col>
    <Col><Image className="img_footer" src={WA} />087770900135</Col>   
    {/* <Col >&copy;<a className="size_txt" href="https://reviz-project.com/">Reviz-Project</a></Col>     */}
  </Row>

</Container>
</div>

</>
      );
    }
  }
  export default Footer;
  