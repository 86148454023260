import React, { Component } from "react";
// import * as ReactBootstrap from "react-bootstrap";
import { Nav,Navbar,Container,NavDropdown, Button,Row,Col,Badge} from "react-bootstrap";
import {NavLink,Redirect} from "react-router-dom";
import $ from 'jquery';
import Modal_Profile from "../content/Modal/Profile/Modal_Profile";
import Modal_Logout from "../content/Modal/Logout/Modal_Logout";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee,faUserEdit,faUserAlt,faBell} from '@fortawesome/free-solid-svg-icons'
import Dropdown from 'react-bootstrap/Dropdown'
import { useSelector,useDispatch } from "react-redux";
import { LoginAuth } from "./Redux/Action/LoginAuth";
import Getsevice from "./../services/GetService"
var usernames = '';
var role = '';
let get_token = localStorage.getItem("token-x");
let a = true


if (get_token == 'rvzys_20') {
  let session = JSON.parse(localStorage.getItem('token'));      
  usernames = session[0].Full_Name;
  role = session[0].Role;


  }
function Notifications() {
  let notif_drop = [];
  Getsevice.getCase_Status().then((res) => {
    let data = res.data;
    let notif_show = 3;
    let data_length = data.length;
    if (data_length <= notif_show) {
      notif_show = data_length;
    }
    console.log("data",data)
    for (let index = 0; index < notif_show; index++) {
      notif_drop.push(
      <Dropdown.Item className="border-bottom">
        <Row>
    <Col sm={12}>
    <h6> Case Name : {data[index].Case_Name}</h6>
    </Col>
    <Col sm={12}>
    <h6> Name : {data[index].Full_Name}</h6>
    </Col>
    <Col sm={12}>
    <h6> Date : {data[index].Datetime}</h6>
    </Col>
    </Row>
        </Dropdown.Item>)
    }
    // console.log("notif_drop",notif_drop.length)
   
  })
  return (
    <Dropdown className="border-bottom d-inline mx-2">
    <Dropdown.Toggle id="dropdown-autoclose-true">
    <FontAwesomeIcon icon={faBell} />
    <Badge bg="secondary">New</Badge>
    </Dropdown.Toggle>
    <Dropdown.Menu id="notif">
        {notif_drop}
        <Dropdown.Item className="border-top">

        </Dropdown.Item>
      </Dropdown.Menu>
      </Dropdown>
  );
}

function ADheader(props) {
  const [modalShow, setModalShow] = React.useState(false);
  const [modalShowx, setModalShowx] = React.useState(false);
  // console.log("islogged",props.IsLoggedIn)
  var badge_num = 0;
  Getsevice.getCase_Status().then((res) => {
    let data = res.data;
    let data_length = data.length;
    badge_num = data_length;
  
  })
  return (
    <>
    <Navbar collapseOnSelect expand="lg"  variant="dark">
    <Container>
    <Navbar.Brand >BiroJasa</Navbar.Brand>
    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
    <Navbar.Collapse id="responsive-navbar-nav">
      <Nav className="me-auto">
        <Nav.Link className="border-bottom btn-outline-info"><NavLink className="no_underline" to="/Home">Home</NavLink></Nav.Link>
        <Nav.Link className="border-bottom btn-outline-info"><NavLink className="no_underline" to="/Profile">Company Profile</NavLink></Nav.Link>
        <Nav.Link className="border-bottom btn-outline-info"><NavLink className="no_underline" to="/Case">Case</NavLink></Nav.Link>
        <NavDropdown className="border-bottom btn-outline-info" title="Services/Pelayanan" id="collasible-nav-dropdown">
          <NavDropdown.Item className="border-bottom btn-outline-info"><NavLink className="no_underline" to="/Pelayanan/Perpanjangan_STNK"> Perpanjangan STNK </NavLink></NavDropdown.Item>
          <NavDropdown.Item className="border-bottom btn-outline-info"><NavLink className="no_underline" to="/Pelayanan/Pengesahan_STNK">Pengesahan STNK 5 tahun</NavLink></NavDropdown.Item>
          <NavDropdown.Item className="border-bottom btn-outline-info"><NavLink className="no_underline" to="/Pelayanan/Balik_nama">Balik nama kendaraan</NavLink></NavDropdown.Item>
          <NavDropdown.Item className="border-bottom btn-outline-info" ><NavLink className="no_underline" to="/Pelayanan/Mutasi_kendaraan">Mutasi kendaraan bermotor antar daerah</NavLink></NavDropdown.Item>
          <NavDropdown.Item className="border-bottom btn-outline-info"><NavLink className="no_underline" to="/Pelayanan/Cabut_berkas">Cabut berkas antar daerah</NavLink></NavDropdown.Item>
          <NavDropdown.Item className="border-bottom btn-outline-info"><NavLink className="no_underline" to="/Pelayanan/STNK_hilang">STNK hilang</NavLink></NavDropdown.Item>
          <NavDropdown.Item ><NavLink className="no_underline" to="/Pelayanan/Kir">Kir</NavLink></NavDropdown.Item>
        </NavDropdown>
      </Nav>
      <Nav>
  
    <Notifications />

      <NavDropdown.Item className="border-bottom no-pointer "><FontAwesomeIcon icon={faUserAlt} /></NavDropdown.Item>
      <NavDropdown className="border-bottom btn-outline-info" title={usernames} id="session_username">
          <NavDropdown.Item className="border-bottom btn-outline-info" onClick={() => setModalShow(true)}><a className="no_underline" > My Profile </a></NavDropdown.Item>
          <NavDropdown.Item className="btn-outline-info" onClick={() => setModalShowx(true)}><a className="no_underline"> Logout </a></NavDropdown.Item>
        </NavDropdown>
      </Nav>
    </Navbar.Collapse>
    </Container>
  </Navbar>
  <Modal_Profile
   show={modalShow}
   onHide={() => setModalShow(false)} />
   <Modal_Logout
   show={modalShowx}
   onHide={() => setModalShowx(false)} />
  </>
  );
}
function Guestheader(props) {
  const [modalShow, setModalShow] = React.useState(false);
  const [modalShowx, setModalShowx] = React.useState(false);

  return (
    <>
    <Navbar collapseOnSelect expand="md"  variant="dark">
    <Container>
    <Navbar.Brand >BiroJasa</Navbar.Brand>
    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
    <Navbar.Collapse id="responsive-navbar-nav">
      <Nav className="me-auto">
        <Nav.Link className="border-bottom btn-outline-info"><NavLink className="no_underline " to="/Home">Home</NavLink></Nav.Link>
        <Nav.Link className="border-bottom btn-outline-info"><NavLink className="no_underline " to="/Profile">Company Profile</NavLink></Nav.Link>
        <Nav.Link className="border-bottom btn-outline-info"><NavLink className="no_underline " to="/My_Case">MyCase</NavLink></Nav.Link>
        <NavDropdown className="no_underline border-bottom btn-outline-info" title="Services/Pelayanan" id="collasible-nav-dropdown">
          <NavDropdown.Item className="border-bottom btn-outline-info" ><NavLink className="no_underline " to="/Pelayanan/Perpanjangan_STNK"> Perpanjangan STNK </NavLink></NavDropdown.Item>
          <NavDropdown.Item className="border-bottom btn-outline-info" ><NavLink className="no_underline" to="/Pelayanan/Pengesahan_STNK">Pengesahan STNK 5 tahun</NavLink></NavDropdown.Item>
          <NavDropdown.Item className="border-bottom btn-outline-info"><NavLink className="no_underline " to="/Pelayanan/Balik_nama">Balik nama kendaraan</NavLink></NavDropdown.Item>
          <NavDropdown.Item className="border-bottom btn-outline-info"><NavLink className="no_underline " to="/Pelayanan/Mutasi_kendaraan">Mutasi kendaraan bermotor antar daerah</NavLink></NavDropdown.Item>
          <NavDropdown.Item className="border-bottom btn-outline-info"><NavLink className="no_underline " to="/Pelayanan/Cabut_berkas">Cabut berkas antar daerah</NavLink></NavDropdown.Item>
          <NavDropdown.Item className="border-bottom btn-outline-info"><NavLink className="no_underline " to="/Pelayanan/STNK_hilang">STNK hilang</NavLink></NavDropdown.Item>
          <NavDropdown.Item ><NavLink className="no_underline " to="/Pelayanan/Kir">Kir</NavLink></NavDropdown.Item>
        </NavDropdown>
      </Nav>
      <Nav>
      <NavDropdown className="no_underline btn-outline-info" title={usernames} id="session_username">
        <div>
          </div>
          <NavDropdown.Item className="border-bottom btn-outline-info" onClick={() => setModalShow(true)}><a className="no_underline" > My Profile </a></NavDropdown.Item>
          <NavDropdown.Item className="btn-outline-info" onClick={() => setModalShowx(true)}><a className="no_underline"> Logout </a></NavDropdown.Item>
        </NavDropdown>
      </Nav>
    </Navbar.Collapse>
    </Container>
  </Navbar>
  <Modal_Profile
   show={modalShow}
   onHide={() => setModalShow(false)} />
      <Modal_Logout
   show={modalShowx}
   onHide={() => setModalShowx(false)} />
  
  </>
  )
}
function Settingheader() {
  const dispatch = useDispatch();
  dispatch(LoginAuth());
  const islog = useSelector(state => state.IsLoggedIn)
  if (role == 1) {
    return <ADheader IsLoggedIn={islog}/>
  }else{
    return <Guestheader />
  }
}
class Header extends Component {
    render() {
      return (
        <Settingheader />
      );
    }
  }

  
  export default Header;
