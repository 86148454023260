import React, { Component,useState } from "react";
import {Modal,Button,Col,Row} from "react-bootstrap";
import { NavLink } from "react-router-dom";
import AuthService from "../../../services/AuthService";

function  logout() {
    AuthService.logout();
  }
function Modal_Logout (props) {
  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
      return (
     <>
<Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="card_purple">
        <Modal.Title className="txt_sett" id="contained-modal-title-vcenter">
          Logout ?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Row>
          <Col sm={12}>
          <a className="txt_login"> Are sure you want to logout ? </a>
          </Col>
          </Row>
      </Modal.Body>
      <Modal.Footer className="card_purple cus_footer">
        <Button onClick={props.onHide} className="btn btn-outline-light btn_outlinex">Close</Button>
        <Button onClick={() => logout()} className="btn btn-outline-light btn_outlinex">logout</Button>
      </Modal.Footer>
    </Modal>
</>
      );

  }
  export default Modal_Logout;
  