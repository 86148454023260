import React, { Component } from "react";
// import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Col,Row } from "react-bootstrap";
// import $ from 'jquery';



class Rules extends Component {
    constructor(props) {
        super(props);
        
    }

    render() {
        return (
            <>
      <Row >
          <Col className="txt-left m-3 " sm>
            <a className="txt_sett">1.  BPKB</a>
                
          </Col>          
      </Row>
      <Row>
      <Col className="txt-left m-3 " sm>
          <a className="txt_sett">2.  STNK</a>
      </Col>         
      </Row>

      <Row>
      <Col className="txt-left m-3 " sm>
      <a className="txt_sett">3.  Cek Fisik bantuan</a>        
      </Col>         
      </Row>

      <Row>
      <Col className="txt-left m-3 " sm>
      <a className="txt_sett">4.  Copy KTP</a>        
      </Col>         
      </Row>
      
      </>
        );
    }
  }
  export default Rules;