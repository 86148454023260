import React, { Component,useState } from "react";
import {Modal,Button,Col,Row} from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee,faUserEdit } from '@fortawesome/free-solid-svg-icons'
import Modal_Get from "./Modal_Get";



function Modal_Profile (props) {
    const [get, setGet] = useState("");
    const [getAttr, setGetAttr] = useState("");
  const [modalShowx, setModalShowx] = React.useState(false);
  let fullname = '';
  let username = '';
  let mobile_number = '';
  let email = '';
  let get_token = localStorage.getItem("token-x");

  if (get_token == 'rvzys_20') {
    let session = JSON.parse(localStorage.getItem('token'));      
    fullname = session[0].Full_Name;
    username = session[0].Username;
    mobile_number = session[0].Mobile_Number;
    email = session[0].Email;
    }
      return (
     <>
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="card_purple">
        <Modal.Title className="txt_sett" id="contained-modal-title-vcenter">
          My Profile 
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Row>
          <Col sm={9}>
          <a className="txt_login"> Full Name </a>
          </Col>
          <Col sm={3}>
          <Button variant="outline-light" className="card_purple" onClick={() => setModalShowx(true)}><FontAwesomeIcon icon={faUserEdit} /> Edit</Button>
          </Col>
          <Col sm={12}>
          <p id="fullname_p">  {fullname}</p>
            </Col>
       
          <Col sm={12}>
          <a className="txt_login"> Mobile Number </a>
          </Col>
          <Col sm={12}>
          <p id="mobile_number_p">  {mobile_number}</p>
          </Col>

          <Col sm={12}>
          <a className="txt_login" > Email </a>
          </Col>
          <Col sm={12}>
          <p id="email_p">  {email}</p>
          </Col>
          </Row>
      </Modal.Body>
      <Modal.Footer className="card_purple cus_footer">
        <Button onClick={props.onHide} className="btn btn-outline-light btn_outlinex">Close</Button>
      </Modal.Footer>
    </Modal>
    <Modal_Get
   show={modalShowx}
   onHide={() => setModalShowx(false)}
   />

</>
      );

  }
  export default Modal_Profile;
  